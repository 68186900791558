<template>
  <section class="section container">
    <div v-if="!propsImport">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li class="is-active">
            <a>Administração</a>
          </li>
          <li class="is-active">
            <a>Importação</a>
          </li>
        </ul>
      </nav>

      <b-collapse class="card m-b-md" :open="false" aria-id="filterContent">
        <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="filterContent">
          <p class="card-header-title">Importação</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'caret-down' : 'caret-up'"></b-icon>
          </a>
        </div>
        <div class="card-content">
          <form @submit.prevent="importCsv">
            <b-field label="Validade">
              <b-datepicker :month-names="datePicker[0]" :day-names="datePicker[1]" v-model="body.validityTime"
                icon="calendar-alt"></b-datepicker>
            </b-field>

            <b-field label="Descrição">
              <b-input v-model="body.description" type="textarea" required></b-input>
            </b-field>

            <b-field label="Curso">
              <b-select placeholder="Select a name" v-model="body.course" required>
                <option v-for="course in courses" :value="course._id" :key="course._id">{{ course.name }}</option>
              </b-select>
            </b-field>

            <b-field>
              <b-field>
                <b-checkbox v-model="body.hasCertification">Tem Certificação</b-checkbox>
              </b-field>
            </b-field>


            <b-field label="Grupo">
              <b-select placeholder="Select a name" v-model="body.teams" required>
                <option v-for="team in teams" :value="team._id" :key="team._id">{{ team.name }}</option>
              </b-select>
            </b-field>

            <b-field class="file">
              <b-upload v-model="file" required>
                <a class="button is-primary">
                  <b-icon icon="upload"></b-icon>
                  <span>Click to upload</span>
                </a>
              </b-upload>
              <span class="file-name" v-if="file">{{ file.name }}</span>
            </b-field>

            <button class="button is-primary">Enviar</button>
          </form>
        </div>
      </b-collapse>

      <b-table :data="data" :loading="isLoading">
        <template slot-scope="props">
          <b-table-column field="description" label="Descrição" sortable>
            {{
              props.row.description
            }}
          </b-table-column>

          <b-table-column field="createdAt" label="Data de criação" sortable>
            <span class="m-r-sm">
              {{
                new Date(props.row.createdAt).toLocaleDateString('pt-BR')
              }}
            </span>
            <span>
              {{
                new Date(props.row.createdAt).toLocaleTimeString('pt-BR')
              }}
            </span>
          </b-table-column>

          <b-table-column field="totalSuccess" label="Sucesso" sortable>
            {{
              props.row.totalSuccess
            }}
          </b-table-column>

          <b-table-column field="totalFailure" label="Erro" sortable>
            {{
              props.row.totalFailure
            }}
          </b-table-column>

          <b-table-column label="Editar" width="100">
            <button class="button is-info" @click="edit(props.row)">Editar</button>
          </b-table-column>

          <b-table-column label="Detalhes" width="100">
            <button class="button is-primary" @click="propsImport = props.row._id">Detalhes</button>
          </b-table-column>

          <b-table-column label="Deletar" width="100">
            <button class="button is-danger" @click="confirm(props.row._id)">Deletar</button>
          </b-table-column>
        </template>
      </b-table>

      <div class="custom">
        <b-pagination class="m-t-md" :total="total" :current.sync="current" :simple="false" :rounded="false"
          :per-page="perPage" aria-next-label="Próxima pagina" aria-previous-label="Pagina anterior"
          aria-page-label="Page" aria-current-label="Pagina atual"></b-pagination>
      </div>

      <Modal :inputs="ediInputs" :active="showModal" :clear="clear" @functionByModal="update"
        @close="showModal = false">
      </Modal>
    </div>

    <importDetail v-if="propsImport" :data="propsImport" @close="propsImport = null"></importDetail>
  </section>
</template>

<script>
import Axios from "axios";

import importDetail from "./importDetail";
import Modal from "@/components/widgets/Modal";

export default {
  components: { importDetail, Modal },
  data() {
    return {
      file: null,
      courses: [],
      teams: [],
      data: [],
      body: {},
      total: null,
      current: 1,
      perPage: null,
      clear: false,
      propsImport: null,
      showModal: null,
      ediInputs: null,
      isLoading: null,
      datePicker: [
        [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ],
        ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
      ]
    };
  },
  methods: {
    importCsv() {
      const fd = new FormData();
      if (!this.file.name.match(".csv"))
        return this.$buefy.dialog.alert({
          title: "OPS!",
          message: "Só é permitido arquivos csv!"
        });
      fd.append("file", this.file, this.file.name);
      fd.append("course", this.body.course);
      fd.append("teams", this.body.teams);
      fd.append("description", this.body.description);
      fd.append("hasCertification", this.body.hasCertification);

      if (this.body.validityTime)
        fd.append("validityTime", this.body.validityTime.toISOString());

      Axios.post(`/v1/imports/`, fd)
        .then(data => {
          if (data.data) {
            this.file = null;
            this.body = {};
            this.propsImport = data.data._id;
            this.getImport();
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getCourse() {
      Axios.get(`/v1/courses?active=true`)
        .then(data => {
          if (data.data) {
            this.courses = data.data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getTeam() {
      Axios.get(`/v1/teams/`, { params: { limit: 50 } })
        .then(data => {
          if (data.data) {
            this.teams = data.data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getImport() {
      this.isLoading = true;
      Axios.get(`/v1/imports?page=${this.current}`)
        .then(data => {
          if (data.data) {
            this.isLoading = null;
            this.data = data.data.data;
            this.total = data.data.total;
            this.perPage = data.data.limit;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    remove(data) {
      Axios.delete(`/v1/imports/${data}`)
        .then(data => {
          if (data.data) {
            this.getImport();
            this.$buefy.notification.open({
              message: "Importação excluido com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    confirm(data) {
      this.$buefy.dialog.confirm({
        message: "Tem certeza que deseja <b>excluir</b> esta importação?",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.remove(data)
      });
    },
    edit(data) {
      this.ediInputs = [
        {
          label: "Descrição",
          type: "textarea",
          model: "description",
          description: data.description,
          _id: data._id,
          required: true
        }
      ];
      this.showModal = true;
    },
    update(data) {
      let dados = Object.assign({}, data);
      Axios.put(`/v1/imports/${this.ediInputs[0]._id}`, dados)
        .then(data => {
          if (data.data) {
            this.getImport();
            this.showModal = false;
            this.$buefy.notification.open({
              message: "Dados atualizados com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    }
  },
  watch: {
    current(current) {
      if (!current) return;
      this.getImport();
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    this.getCourse();
    this.getTeam();
    this.getImport();
  }
};
</script>

<style lang="css" scoped>
.custom {
  display: flex;
  justify-content: flex-end;
}
</style>