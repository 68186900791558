<template>
  <div>
    <button class="button is-primary m-b-sm" @click="$emit('close')">
      Voltar
    </button>
    <p v-if="dados.description" class="m-t-sm m-b-sm">
      {{ dados.description }}
    </p>
    <div class="error" v-if="dados.failure && dados.failure[0]">
      <b-table :data="dados.failure">
        <template slot-scope="props">
          <b-table-column field="username" label="Usuário" sortable>{{
            props.row.username
          }}</b-table-column>

          <b-table-column label="message">{{
            translate(props.row.message)
          }}</b-table-column>
        </template>
      </b-table>
    </div>

    <div class="sucesso" v-if="dados.success && dados.success[0]">
      <b-table :data="dados.success">
        <template slot-scope="props">
          <b-table-column field="name" label="Usuário" sortable>{{
            props.row.name
          }}</b-table-column>
          <b-table-column field="username" label="Login" sortable>{{
            props.row.username
          }}</b-table-column>
          <b-table-column field="password" label="Senha" sortable>{{
            props.row.password
          }}</b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
export default {
  props: {
    data: String
  },
  data() {
    return {
      dados: []
    };
  },
  methods: {
    getImport() {
      Axios.get(`/v1/imports/${this.data}?fields=success failure`)
        .then(data => {
          if (data.data) {
            this.dados = data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    translate(data) {
      if (!data) return;

      let temp = [];
      if (data.includes('already in use'))
        temp.push(`${data.split('already in use')[0]} precisa ser único.`);
      if (data.includes('invalid'))
        temp.push(`${data.split('invalid')[1]} inválido.`);
      return temp[0] ? temp.toString() : data;
    }
  },
  mounted() {
    this.getImport();
  }
};
</script>

<style lang="scss" scoped>
.error {
  border: #ff0000 solid 2px;
  margin-bottom: 1rem;
}
.sucesso {
  border: #00a000 solid 2px;
}
</style>
